@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px); /* Adjust the Y-axis movement as needed */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 2s; /* Adjust duration as needed */
    animation-fill-mode: both;
  }
  